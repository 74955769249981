import React from 'react';
import { Oval } from 'react-loader-spinner';

interface LoadingOvalProps {
  height: number;
  width: number;
  strokeWidth: number;
  color?: string;
  secondaryColor?: string;
  ariaLabel: string;
}

const LoadingOval = ({
  height,
  width,
  strokeWidth,
  color = 'red',
  secondaryColor = 'white',
  ariaLabel,
}: LoadingOvalProps): JSX.Element => {
  return (
    <Oval
      ariaLabel={ariaLabel}
      height={height}
      width={width}
      strokeWidth={strokeWidth}
      color={color}
      secondaryColor={secondaryColor}
    />
  );
};

export default LoadingOval;
