/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import LoadingOval from '../LoadingOval';
import ProductDetails from './ProductDetails';
import Image from '../Image';
import Link from '../link/Link';
import constants from '../../../utils/constants';
import { monthLower, getRoot } from '../../../utils/utils';
import { Book } from '../../../graphqlTypes';
import PriceProductPreview from './PriceProductPreview';

interface ProductPreviewProps {
  element?: Book;
  category?: string;
  canUseDom?: boolean;
  className?: string;
  slider?: boolean;
  results?: boolean;
  id?: string;
  withBorder?: boolean;
  isLoading?: boolean;
  isPreview?: boolean;
  onClick?: (productSelected: Book) => void;
  loadingDimentions?: {
    height?: number;
    width?: number;
    strokeWidth?: number;
  };
}

const ProductPreview = ({
  element,
  category,
  className,
  slider,
  results,
  withBorder,
  isPreview = true,
  isLoading = false,
  onClick,
  loadingDimentions,
}: ProductPreviewProps): JSX.Element => {
  const [state, setState] = useState({ element });
  const router = useRouter();
  const { title, titleFriendly = 'untitled' } = state.element;

  useEffect(() => {
    setState({ element });
  }, [element]);

  const isThereLanguage =
    state.element?.book?.lang?.toString() !== 'XX' &&
    state.element?.book?.lang?.toString() !== 'ES';

  const getHref = (): string => {
    let id: number | string = null;
    const { product_type, id: idElement, ean } = state.element || {};
    if (state.element) {
      id = ['model', 'material'].includes(product_type) ? idElement : ean;
    } else {
      id = 0;
    }

    const categoryProductType =
      category !== 'magazine' && (state.element?.product_type || 'magazine');
    const root = getRoot(categoryProductType);
    return `${root + id}/${titleFriendly}`;
  };

  const handleOnClick = (): void => {
    const ref = getHref();
    router.push(ref);
    if (!onClick) {
      return;
    }
    onClick(element);
  };

  if (!state.element || (category && state.element.product_type !== category)) {
    return null;
  }

  return (
    <Link
      href={getHref()}
      className={`item productPreview previewHolder ${className || ''}${slider ? ' slider' : ''}${
        results ? ' result' : ''
      }`}
      onClick={handleOnClick}
    >
      <div className={`top ${state.element.not_released ? 'comingSoon' : ''}`}>
        {state.element.not_released && <span className="comingSoonSpan">Próximamente</span>}

        <div style={{ opacity: isLoading ? 0.6 : 1 }}>
          <Image
            className="noImage"
            loading="eager"
            alt={
              state.element.product_type === constants.allProductTypes[0]
                ? `Portada del libro ${state.element.ean} ${state.element.title}`
                : state.element.title
            }
            addCdnPrefix={!state.element?.mainImg}
            src={
              state.element.mainImg
                ? `${state.element.mainImg}`
                : 'img/no-image.jpg'
            }
            width="190"
            height="220"
            withBorder={withBorder}
            isPreview={isPreview}
          />
          {isLoading && (
            <div style={{ position: 'absolute' }}>
              <LoadingOval
                ariaLabel="loading-indicator-product-preview"
                height={loadingDimentions?.height || 60}
                width={loadingDimentions?.width || 60}
                strokeWidth={loadingDimentions?.strokeWidth || 3}
                color="red"
                secondaryColor="white"
              />
            </div>
          )}
        </div>
      </div>

      <div className="bottom">
        {slider ? (
          isThereLanguage ? (
            <h2 lang={state.element?.book?.lang?.toLowerCase()} className="title">
              {title || ''}
            </h2>
          ) : (
            <h2 className="title">{title || ''}</h2>
          )
        ) : isThereLanguage ? (
          <h3 lang={state.element?.book?.lang?.toLowerCase()} className="title">
            {title || ''}
          </h3>
        ) : (
          <h3 className="title">{title || ''}</h3>
        )}

        {state.element?.book &&
          state.element?.product_type === 'book' &&
          state.element?.book?.edition && (
            <span>
              {`${state.element?.book?.edition?.number || 1}ª Edición${
                state.element?.book?.edition?.month
                  ? ` / ${monthLower[state.element.book.edition.month - 1]} `
                  : ' '
              }${state.element.book.edition.year || ''}`}
            </span>
          )}

        <ProductDetails
          category={category}
          results={results}
          element={state.element}
          slider={slider}
        />

        {slider ? <div className="divider" /> : null}
        {state.element && <PriceProductPreview element={state.element} />}
      </div>
    </Link>
  );
};

export default ProductPreview;
