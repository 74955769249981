import React from 'react';

import { getTitleProduct } from '../ProductListed/priceProductListed.util';
import { Book } from '../../../graphqlTypes';

const customFormatNumber = (num: number): string =>
  Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(num);

interface PriceProductPreviewProps {
  element: Book;
}

const PriceProductPreview: React.FC<PriceProductPreviewProps> = ({ element }) => {
  const { prices, relatedInfo, priceWithDiscount, product_type } = element;
  const { sale: salePrice } = prices || {};
  const { sale: salePriceOther } = relatedInfo?.prices || {};
  const descriptionTitle = getTitleProduct(product_type);

  return !relatedInfo ? (
    <div className="pricesEbook">
      <div className="priceTypeWrapper">
        {descriptionTitle ? <p className="priceType">{descriptionTitle}</p> : null}

        <p className="price">
          {salePrice && <em className="SCRATCHED">{`${customFormatNumber(salePrice)} `}</em>}
          {prices && priceWithDiscount ? customFormatNumber(priceWithDiscount) : ''}
        </p>
      </div>
    </div>
  ) : (
    <div className="pricesEbook">
      <div className="priceTypeWrapper">
        <p className="priceType">{product_type === 'book' ? 'Libro' : product_type}</p>

        <p className="price">
          {salePrice && <em className="SCRATCHED">{`${customFormatNumber(salePrice)} `}</em>}

          {priceWithDiscount ? customFormatNumber(priceWithDiscount) : ''}
        </p>
      </div>

      <div className="priceTypeWrapper">
        <p className="priceType">
          {relatedInfo.product_type === 'book' ? 'Libro' : relatedInfo.product_type}
        </p>

        <p className="price">
          {salePriceOther && (
            <em className="SCRATCHED">{`${customFormatNumber(salePriceOther)} `}</em>
          )}

          {relatedInfo?.priceWithDiscount ? customFormatNumber(relatedInfo.priceWithDiscount) : ''}
        </p>
      </div>
    </div>
  );
};

export default PriceProductPreview;
