import React from 'react';
import 'lazysizes';
import Imgix from 'react-imgix';

import { urlImages } from '../../utils/constants';

interface ComponentProps {
  id?: string;
  className?: string;
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  title?: string;
  isPreview?: boolean;
  onClick?: (index) => void;
  index?: number;
  addCdnPrefix?: boolean;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
  withBorder?: boolean;
  loading?: 'eager' | 'lazy';
  sizes?: string;
}

const Image = ({
  id,
  className: classes,
  src,
  alt,
  width,
  height,
  title,
  onClick: onClickFnProp,
  index,
  addCdnPrefix,
  withBorder,
  isPreview,
  loading = 'lazy',
  sizes = '100vw',
}: ComponentProps): JSX.Element => {
  let rightSrc = src;
  const onClick = (): void => {
    if (onClickFnProp) onClickFnProp(index);
  };

  if (addCdnPrefix || src.includes('no-image')) rightSrc = `${urlImages}${src}`;
  if (withBorder && !src.includes('no-image')) {
    if(isPreview) {
      rightSrc = `${src}&border=1,3000`
    } else {
      rightSrc = `${src}&border=2,3000`
    }
    if(!['.jpg?', '.png?', '.jpeg?'].every(extensionImage => rightSrc.includes(extensionImage))){
      if(isPreview) {
        rightSrc = `${src}?border=1,3000`
      } else {
        rightSrc = `${src}?border=2,3000`
      }
    }
  };
  
  return (
    <Imgix
      sizes={sizes}
      className={`img ${loading === 'lazy' ? 'lazyload' : ''} blur-up ${classes}`}
      htmlAttributes={{
        onClick,
        id,
        src,
        loading,
        title,
        alt,
        srcSet: rightSrc,
        className: `img ${loading === 'lazy' ? 'lazyload' : ''} blur-up ${classes}`,
      }}
      imgixParams={{ w: width, h: height }}
      src={rightSrc}
      height={Number(height)}
      width={Number(width)}
    />
  );
};

export default Image;
