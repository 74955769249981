import { useState } from 'react';

import { isEqual } from 'lodash';
import { Book } from '../graphqlTypes';

interface LoadingListProductsHookReturn {
  currentProductClicked: (product: Book) => void;
  isClickedProduct: (element: Book) => boolean;
}

const useLoadingListProducts = (): LoadingListProductsHookReturn => {
  const [productSelected, setProductSelected] = useState<Book>(null);

  const currentProductClicked = (product: Book): void => {
    setProductSelected(product);
  };

  const isClickedProduct = (element: Book): boolean => {
    return isEqual(element, productSelected);
  };

  return {
    isClickedProduct,
    currentProductClicked,
  };
};

export default useLoadingListProducts;
