import React, { useEffect, useState } from 'react';
import useLoadingListProducts from '../../../hooks/useLoadingListProducts';
import { Book } from '../../../graphqlTypes';
import ProductPreview from '../productPreview/ProductPreview';
import OptionsHomeProduct from './homeRestyle.model';

interface ProductsHomeProps {
  reverse?: boolean;
  title: string;
  option: OptionsHomeProduct;
  booksFeatured?: Book[];
  booksNews?: Book[];
}

interface ProductsHomeState {
  items: Book[];
}

const ProductsHome: React.FC<ProductsHomeProps> = ({
  reverse = false,
  title,
  booksFeatured,
  booksNews,
}: ProductsHomeProps) => {
  const [state, setState] = useState<ProductsHomeState>({
    items: booksFeatured || booksNews,
  });
  const { currentProductClicked, isClickedProduct } = useLoadingListProducts();

  useEffect(() => {
    setState({ items: booksFeatured || booksNews });
  }, [booksFeatured, booksNews]);

  return (
    state.items?.length > 0 && (
      <div className={`productsHome-style ${reverse ? ' reverse' : ''}`}>
        <div className="maxContainer">
          <h2 className="titleMobile">{title}</h2>
          <div className="contentBox">
            <h2 className="titleDesktop">{title}</h2>
            <div className="itemsBoxContainer">
              <div className="itemBox bigBoss">
                <ProductPreview
                  element={state.items?.[0]}
                  isLoading={isClickedProduct(state.items?.[0])}
                  onClick={currentProductClicked}
                  loadingDimentions={{ height: 300 }}
                />
              </div>
              {state?.items?.slice(1).map((book) => (
                <div key={book.ean} className="itemBox">
                  <ProductPreview
                    element={book}
                    isLoading={isClickedProduct(book)}
                    onClick={currentProductClicked}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default React.memo(ProductsHome);
