import React from 'react';
import { Book } from '../../../graphqlTypes';

interface ProductDetailsProps {
  element: Book;
  results?: boolean;
  category?: string;
  slider?: boolean;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ slider, results, category, element }) => {
  if (!results || category === 'magazine' || !!slider) return null;

  let author = null;

  if (element?.tags) {
    const authorTag = element?.tags.find((tag) => tag.category === 'authors');

    if (authorTag) {
      [author] = authorTag.items;
    }
  }

  if (!author && !element?.tags && element?.book?.authors?.[0]) {
    author = element.book.authors[0].name;
  }

  return <h4 className="subTitle">{author}</h4>;
};

export default ProductDetails;
